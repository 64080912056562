<template>
    <div>
        <table-authors/>
    </div>
</template>

<script>
import TableAuthors from '@/components/authors/table.vue'
export default {
  components: { TableAuthors },
    data () {
        return {
           
        }
    }
    
}
</script>